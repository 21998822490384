import { h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import root from 'window-or-global';
import { coalesce } from 'src/lib/tools';
import { Client } from 'src/lib/client';
import useClient from 'src/hooks/client';
import { useSession, getSessionState } from 'src/hooks/session';
import useAccount from 'src/hooks/account';

export const SessionManagement = ({ children }) => {
    const [session, setSession] = useSession();
    const [, setAccount] = useAccount();
    const client = useClient();
    const sessionState = getSessionState(session);
    const { accountId } = coalesce(session, {});
    const { sessionId: currentSessionId } = coalesce(session, {});

    useEffect(() => (async () => {
        switch (sessionState) {
            case 'disconnected':
                {
                    try {
                        const sessionId = JSON.parse(coalesce(root.localStorage.getItem('app.sessionId'), '{}')).value;
                        if (sessionId) {
                            const cli = new Client(sessionId);
                            const s = await cli.server.session.get_current();
                            if (s) {
                                setSession(s);
                                return;
                            }
                        }
                    } catch (e) { }
                    const s = await client.server.session.open_session(null, 'TEST ROM1');
                    setSession(s);
                }
                break;
        }
    })(), [sessionState, client, setSession]);

    useEffect(() => (async () => {
        root.localStorage.setItem('app.sessionId', JSON.stringify({ value: currentSessionId }));
    })(), [currentSessionId]);

    useEffect(() => (async () => {
        if (accountId) {
            const currentAccount = await client.infrastructure.account.get_infos(accountId);
            setAccount(currentAccount);
        } else {
            setAccount(undefined);
        }
    })(), [accountId, client, setAccount]);

    return <Fragment>{children}</Fragment>;
};