import { h, Fragment } from 'preact';
import { useState, useMemo, useCallback } from 'preact/hooks';
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight, faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import orderBy from 'lodash/orderBy';
import classNames from 'classnames';
import { coalesce, guard } from 'src/lib/tools';
import { Icon } from 'src/components/icon';
import { Loading } from 'src/components/loading';

const sortIcons = {
    asc: faSortUp,
    desc: faSortDown,
    none: faSort,
};

export const HeaderCell = ({ fixed = false, direction, toggle, children, class: className }) => (
    <th class={classNames(className, { '-fixed': fixed }, 'clickable nowrap')} onClick={toggle}>
        <div class="flex -row">
            <span class="-fill">{children}</span>
            <Icon fixedWidth icon={sortIcons[direction]} />
        </div>
    </th>
);

export const Grid = ({ fixedLayout = false, list, headerPanel, headerRowTemplate, rowTemplate, pageSize = 100, class: className, style }) => {
    const [pageIndexState, setPageIndex] = useState(0);
    const [{ sorter, sortDirection = 'asc' } = {}, setSorterState] = useState();
    const pageCount = Math.ceil(coalesce(list, []).length / pageSize) || 1;
    const pageIndex = pageIndexState % pageCount;
    const isLoading = !list;
    const prevPage = useCallback(() => setPageIndex(i => i - 1), [setPageIndex]);
    const nextPage = useCallback(() => setPageIndex(i => i + 1), [setPageIndex]);
    const firstPage = useCallback(() => setPageIndex(0), [setPageIndex]);
    const lastPage = useCallback(() => setPageIndex(pageCount - 1), [setPageIndex, pageCount]);

    const sortedList = useMemo(
        () => sorter ? orderBy(coalesce(list, []), [sorter], [sortDirection]) : coalesce(list, []),
        [list, sorter, sortDirection],
    );

    const page = useMemo(
        () => sortedList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
        [sortedList, pageSize, pageIndex],
    );

    const useSorter = (sort, inputs = []) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const cb = useCallback(sort, inputs);
        const isSelected = cb === sorter;

        return {
            direction: !isSelected ?
                'none' :
                sortDirection,

            toggle: useCallback(() => {
                const newDirection =
                    !isSelected ?
                        'asc' :
                        sortDirection === 'asc' ?
                            'desc' :
                            'asc';

                setSorterState({
                    sorter: cb,
                    sortDirection: newDirection,
                });
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [setSorterState, cb, isSelected, sortDirection]),
        };
    };

    return <div class={classNames(className, 'data-grid')} style={style}>
        {guard(!isLoading && headerPanel) &&
            <div class="border-bottom flex -row -X-stretch -wrap padding-medium gap-medium hide-empty">
                {headerPanel()}
            </div>
        }

        {guard(isLoading) && <Loading />}

        {guard(!isLoading) &&
            <div class="table-wrapper">

                <table style={{ tableLayout: fixedLayout ? 'fixed' : 'auto' }}>
                    {guard(headerRowTemplate) &&
                        <thead>
                            {headerRowTemplate(useSorter)}
                        </thead>
                    }

                    {guard(page && page.length && rowTemplate) &&
                        <tbody>
                            {page.map(rowTemplate)}
                        </tbody>
                    }
                </table>
            </div>
        }

        {guard(!isLoading && pageCount > 1) &&
            <div class="border-top flex -row -end padding-medium gap-medium">
                {guard(pageIndex > 0) && <Fragment>
                    <button class="button round" style={{ width: '2em' }} onClick={firstPage}><Icon fixedWidth icon={faAngleDoubleLeft} /></button>
                    <button class="button round" style={{ width: '2em' }} onClick={prevPage}><Icon fixedWidth icon={faAngleLeft} /></button>
                </Fragment>}

                <span class="margin-auto">Page {pageIndex + 1}/{pageCount}</span>

                {guard(pageIndex < pageCount - 1) && <Fragment>
                    <button class="button round" style={{ width: '2em' }} onClick={nextPage}><Icon fixedWidth icon={faAngleRight} /></button>
                    <button class="button round" style={{ width: '2em' }} onClick={lastPage}><Icon fixedWidth icon={faAngleDoubleRight} /></button>
                </Fragment>}
            </div>
        }
    </div>;
};