import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { faFax, faMobileAlt, faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { compose, coalesce, guard } from 'src/lib/tools';
import useAccount from 'src/hooks/account';
import usePersistedState from 'src/hooks/local-storage';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';
import { FaxModule } from '../fax/module';
import { SmsModule } from '../sms/module';
import { AudioModule } from '../audio/module';
import { NoModule } from './no-module';

export const ModulespaceUI = ({
    I18n, style, account, noneIsSelected,
    faxIsActive, faxIsSelected, setFaxAsCurrent,
    smsIsActive, smsIsSelected, setSMSAsCurrent,
    audioIsActive, audioIsSelected, setAudioAsCurrent,
}) => (
    <aside style={{
        ...style,
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '". modules ." "module module module" ',
        gap: '.25rem',
        placeItems: 'stretch stretch',
    }} >
        <nav style={{
            gridArea: 'modules',
            display: 'grid',
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'auto',
            gridAutoFlow: 'column',
            gap: '.25rem',
            placeItems: 'center center',
        }} class="flex -row -center -X-stretch">
            {guard(faxIsActive) &&
                <button
                    style={{ height: '4rem', width: '4rem' }}
                    class={classNames({ '-active': faxIsSelected }, 'button -column round')}
                    onClick={setFaxAsCurrent}
                >
                    <Icon fixedWidth style={{ fontSize: '1.3rem' }} icon={faFax} />
                    <span style={{ fontSize: '.8rem' }}>{I18n.t('Fax')}</span>
                </button>
            }

            {guard(smsIsActive) &&
                <button
                    style={{ height: '4rem', width: '4rem' }}
                    class={classNames({ '-active': smsIsSelected }, 'button -column round ')}
                    onClick={setSMSAsCurrent}
                >
                    <Icon fixedWidth style={{ fontSize: '1.3rem' }} icon={faMobileAlt} />
                    <span style={{ fontSize: '.8rem' }}>{I18n.t('SMS')}</span>
                </button>
            }

            {guard(audioIsActive) &&
                <button
                    style={{ height: '4rem', width: '4rem' }}
                    class={classNames({ '-active': audioIsSelected }, 'button -column round ')}
                    onClick={setAudioAsCurrent}
                >
                    <Icon fixedWidth style={{ fontSize: '1.3rem' }} icon={faMicrophoneAlt} />
                    <span style={{ fontSize: '.8rem' }}>{I18n.t('Audio')}</span>
                </button>
            }
        </nav>
        {guard(faxIsSelected) && <FaxModule style={{ gridArea: 'module' }} account={account} />}
        {guard(smsIsSelected) && <SmsModule style={{ gridArea: 'module' }} account={account} />}
        {guard(audioIsSelected) && <AudioModule style={{ gridArea: 'module' }} account={account} />}
        {guard(noneIsSelected) && <NoModule style={{ gridArea: 'module' }} />}
    </aside>
);

export const ModulespaceLogic = ({ ...props }) => {
    const [account] = useAccount();
    const [current, setCurrent] = usePersistedState('module-side.current', 'no');
    const setFaxAsCurrent = useCallback(() => setCurrent('fax'), [setCurrent]);
    const setSMSAsCurrent = useCallback(() => setCurrent('sms'), [setCurrent]);
    const setAudioAsCurrent = useCallback(() => setCurrent('audio'), [setCurrent]);
    const { faxIsActive, smsIsActive, audioIsActive } = coalesce(account, {});

    const realCurrent = useMemo(() => {
        switch (current) {
            case 'fax':
                if (faxIsActive) return 'fax';
                if (smsIsActive) return 'sms';
                if (audioIsActive) return 'audio';
                break;

            case 'sms':
                if (smsIsActive) return 'sms';
                if (audioIsActive) return 'audio';
                if (faxIsActive) return 'fax';
                break;

            case 'audio':
                if (audioIsActive) return 'audio';
                if (faxIsActive) return 'fax';
                if (smsIsActive) return 'sms';
                break;
        }
    }, [current, faxIsActive, smsIsActive, audioIsActive]);

    const noneIsSelected = realCurrent === 'no';
    const faxIsSelected = realCurrent === 'fax';
    const smsIsSelected = realCurrent === 'sms';
    const audioIsSelected = realCurrent === 'audio';

    return {
        ...props,
        account,
        noneIsSelected,
        faxIsActive,
        faxIsSelected,
        setFaxAsCurrent,
        smsIsActive,
        smsIsSelected,
        setSMSAsCurrent,
        audioIsActive,
        audioIsSelected,
        setAudioAsCurrent,
    };
};

export const Modulespace = compose(
    LogicHOC(ModulespaceLogic),
    I18nHOC(),
)(ModulespaceUI);