import { faEdit, faFlag, faTable, faArchive, faCalendarPlus, faUserEdit, faSort, faFilter, faEnvelopeOpenText, faCheckDouble, faFileAlt, faClone, faICursor, faStop, faPause, faPlay, faFax, faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import { route, getCurrentUrl } from 'preact-router';
import { useClient } from 'src/hooks/client';
import { useAccount } from 'src/hooks/account';
import { useI18n } from 'src/hooks/i18n';
import { useSession, checkSecurityMin } from 'src/hooks/session';

export const useCampaignRight = campaign => {
    const [{ securityLevel } = {}] = useSession();
    const [{ accountId } = {}] = useAccount();

    return {
        canSelectOwnerAccount:
            campaign &&
            (accountId !== campaign.ownerId),

        canShowCampaign:
            campaign &&
            (getCurrentUrl() !== `/fax/campaign/${campaign.campaignId}`),

        canShowReport:
            campaign &&
            !['waitingSignature', 'new'].includes(campaign.state) &&
            checkSecurityMin(securityLevel, 'demo'),

        canRename:
            campaign &&
            checkSecurityMin(securityLevel, 'demo'),

        canPause:
            campaign &&
            ['sending', 'scheduled', 'waitingNextSlot', 'unavailableCredit'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canFilterCountries:
            campaign &&
            ['waitingSignature', 'new'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canResume:
            campaign &&
            ['sleeping'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canModifyConstraint:
            campaign &&
            ['waitingSignature', 'new', 'sending', 'retrying', 'scheduled', 'unavailableCredit', ' sleeping', 'waitingNextSlot'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canEnd:
            campaign &&
            ['sending', 'sleeping', 'scheduled', 'waitingNextSlot', 'unavailableCredit'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canArchivate:
            campaign &&
            ['finished'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'admin'),

        canSendTest:
            campaign &&
            ['waitingSignature', 'waitingNextSlot', 'scheduled'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'utilisateur'),

        canRemove:
            campaign &&
            ((
                ['waitingSignature', 'new', 'templatenew', 'templateReady'].includes(campaign.state) &&
                !campaign.isOnDemand &&
                checkSecurityMin(securityLevel, 'demo')
            ) || (
                !campaign.isOnDemand &&
                checkSecurityMin(securityLevel, 'admin')
            )),

        canModifyPlans:
            campaign &&
            ['waitingSignature', 'new'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canAddPlan:
            campaign &&
            !['waitingSignature', 'new', 'stored', 'templatenew', 'templateReady'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canModifyPriority:
            campaign &&
            ['waitingSignature', 'new', 'sending', 'retrying', 'scheduled', 'unavailableCredit', ' sleeping', 'waitingNextSlot'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'admin'),

        canDuplicate:
            campaign &&
            !['templatenew', 'templateReady'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canValidate:
            campaign &&
            ['waitingSignature'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canModifyOwner:
            campaign &&
            checkSecurityMin(securityLevel, 'admin'),

        canModifyRedListProperty:
            campaign &&
            !['storing', 'stored'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canEdit:
            campaign &&
            ['new', 'waitingSignature', 'templatenew', 'templateReady'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),

        canModifyDocument:
            campaign &&
            ['new', 'waitingSignature', 'templatenew', 'templateReady'].includes(campaign.state) &&
            !campaign.isOnDemand &&
            checkSecurityMin(securityLevel, 'demo'),
    };
};

export const useCampaignMenuEntries = (campaign, onDismiss) => {
    const client = useClient();
    const [, setAccount] = useAccount();
    const { I18n } = useI18n();

    const {
        canSelectOwnerAccount, canShowCampaign, canRename, canPause,
        canResume, canModifyConstraint, canEnd, canArchivate,
        canSendTest, canRemove, canModifyPlans, canAddPlan, canModifyPriority,
        canDuplicate, canValidate, canModifyOwner, canModifyRedListProperty,
        canEdit, canModifyDocument, canShowReport, canFilterCountries,
    } = useCampaignRight(campaign);

    return [
        canSelectOwnerAccount && {
            icon: faUser,
            text: () => I18n.t('Selectionner le compte'),
            cb: async () => {
                const currentAccount = await client.infrastructure.account.get_infos(campaign.ownerId);
                setAccount(currentAccount);
                if (onDismiss) onDismiss();
            },
        },
        canShowCampaign && {
            icon: faFax,
            text: () => I18n.t('Afficher la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}`);
                if (onDismiss) onDismiss();
            },
        },
        canShowReport && {
            icon: faTable,
            text: () => I18n.t('Afficher le rapport détaillé'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/report`);
                if (onDismiss) onDismiss();
            },
        },
        canEdit && {
            icon: faEdit,
            text: () => I18n.t('Modifier la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/wizzard`);
                if (onDismiss) onDismiss();
            },
        },
        canRename && {
            icon: faICursor,
            text: () => I18n.t('Renommer la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/rename`);
                if (onDismiss) onDismiss();
            },
        },
        canSendTest && {
            icon: faEnvelopeOpenText,
            text: () => I18n.t('Envoyer un test'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/tests`);
                if (onDismiss) onDismiss();
            },
        },
        canValidate && {
            icon: faPlay,
            text: () => I18n.t('Valider la campagne'),
            cb: () => {
                route(`/fax/campaign/${campaign.campaignId}/validate`);
                if (onDismiss) onDismiss();
            },
        },
        canDuplicate && {
            icon: faClone,
            text: () => I18n.t('Dupliquer la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/duplicate`);
                if (onDismiss) onDismiss();
            },
        },
        canModifyDocument && {
            icon: faFileAlt,
            text: () => I18n.t('Modifier le document'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/document`);
                if (onDismiss) onDismiss();
            },
        },
        canModifyConstraint && {
            icon: faCheckDouble,
            text: () => I18n.t('Modifier les restrictions'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/constraints`);
                if (onDismiss) onDismiss();
            },
        },
        canModifyRedListProperty && {
            icon: faFilter,
            text: () => I18n.t('Modifier le respect des listes noires'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/blacklists`);
                if (onDismiss) onDismiss();
            },
        },
        canFilterCountries && {
            icon: faFlag,
            text: () => I18n.t('Filtrer les pays de la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/countries`);
                if (onDismiss) onDismiss();
            },
        },
        canModifyPriority && {
            icon: faSort,
            text: () => I18n.t('Modifier la priorité d’envoi'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/priority`);
                if (onDismiss) onDismiss();
            },
        },
        canModifyOwner && {
            icon: faUserEdit,
            text: () => I18n.t('Modifier le propriétaire'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/owner`);
                if (onDismiss) onDismiss();
            },
        },
        canModifyPlans && {
            icon: faCalendarPlus,
            text: () => I18n.t('Modifier le plan d\'envoi'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/plans`);
                if (onDismiss) onDismiss();
            },
        },
        canAddPlan && {
            icon: faCalendarPlus,
            text: () => I18n.t('Ajouter un nouveau plan d\'envoi'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/add-plan`);
                if (onDismiss) onDismiss();
            },
        },
        canPause && {
            icon: faPause,
            text: () => I18n.t('Suspendre la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/suspend`);
                if (onDismiss) onDismiss();
            },
        },
        canResume && {
            icon: faPlay,
            text: () => I18n.t('Reprendre la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/resume`);
                if (onDismiss) onDismiss();
            },
        },
        canEnd && {
            icon: faStop,
            text: () => I18n.t('Terminer maintenant la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/terminate`);
                if (onDismiss) onDismiss();
            },
        },
        canEnd && {
            icon: faStop,
            text: () => I18n.t('Terminer le plan en cours'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/terminate-current-plan`);
                if (onDismiss) onDismiss();
            },
        },
        canArchivate && {
            icon: faArchive,
            text: () => I18n.t('Archiver la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/archivate`);
                if (onDismiss) onDismiss();
            },
        },
        canRemove && {
            icon: faTrash,
            text: () => I18n.t('Supprimer la campagne'),
            cb: async () => {
                route(`/fax/campaign/${campaign.campaignId}/delete`);
                if (onDismiss) onDismiss();
            },
        },
    ].filter(i => i);
};