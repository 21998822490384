import milliseconds from 'date-fns/milliseconds';
import format from 'date-fns/format';
import startOfToday from 'date-fns/startOfToday';
import addMinutes from 'date-fns/addMinutes';

export const minutes2time = minutes => format(addMinutes(startOfToday(), minutes), 'HH:mm');
export const time2minutes = time => Math.ceil(milliseconds({ hours: time.split(':')[0], minutes: time.split(':')[1] }) / 1000 / 60);
export const formatMinutes = (minutes, dateOptions) => format(addMinutes(startOfToday(), minutes), 'p', dateOptions);
export const formatIsoDateTime = date => format(date, 'yyyy-MM-dd\'T\'HH:mm');
export const formatIsoDate = date => format(date, 'yyyy-MM-dd');
export const int2dt = i => new Date((i * 1000) + (new Date(i * 1000).getTimezoneOffset() * 60 * 1000));
export const dt2int = dt => Math.ceil((dt.getTime() / 1000) - (dt.getTimezoneOffset() * 60));