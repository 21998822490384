import { h } from 'preact';
import { Router } from 'preact-router';
import { Link } from 'preact-router/match';
import AsyncRoute from 'preact-async-route';
import { faTimesCircle, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { compose, coalesce } from 'src/lib/tools';
import { usePagesHistory } from 'src/hooks/pages-history';
import { LogicHOC } from 'src/components/logic-hoc';
import { Icon } from 'src/components/icon';
import { Redirect } from 'src/components/redirect';
import getComponent from './workspaces';

export const WorkspaceUI = ({ history, getRemoveCB, style }) => (
    <div style={style} class="flex -column">
        <nav style={{ gridArea: 'pages' }} class="tabs-selector">
            {history.map(page =>
                <Link key={page.href} class="flex -row -center -X-center gap-medium padding-medium" activeClassName="-active" href={page.href} title={page.title}>
                    <Icon fixedWidth icon={coalesce(page.icon, faStickyNote)} />
                    <span class="-fill">{page.title}</span>
                    <Icon fixedWidth onClick={getRemoveCB(page)} icon={faTimesCircle} />
                </Link>)}
        </nav>

        <main style={{ gridArea: 'main' }} class="-fill round scroll-container flex -column padding-medium bg-light">
            <div>
                <Router>
                    <AsyncRoute
                        path="/profile/:accountId/:rest*"
                        getComponent={getComponent('infrastructure.profile')}
                    />
                    <AsyncRoute
                        path="/fax/campaigns/:rest*"
                        getComponent={getComponent('fax.campaigns')}
                    />
                    <AsyncRoute
                        path="/fax/campaign/:campaignId/:rest*"
                        getComponent={getComponent('fax.campaign')}
                    />
                    <AsyncRoute
                        path="/fax/blacklist/:accountId/:rest*"
                        getComponent={getComponent('fax.blacklist')}
                    />
                    <AsyncRoute
                        path="/fax/history/:accountId/:rest*"
                        getComponent={getComponent('fax.history')}
                    />
                    <AsyncRoute
                        path="/fax/shop/:accountId/:rest*"
                        getComponent={getComponent('fax.shop')}
                    />
                    <AsyncRoute
                        path="/welcome/:rest*"
                        getComponent={getComponent('infrastructure.welcome')}
                    />
                    <Redirect default to="/welcome" />
                </Router>
            </div>
        </main>
    </div>
);

export const WorkspaceLogic = ({ ...props }) => {
    const { history, remove } = usePagesHistory();
    const getRemoveCB = page => e => {
        e.stopPropagation();
        e.preventDefault();
        remove(page);
    };

    return { ...props, history, getRemoveCB };
};


export const Workspace = compose(
    LogicHOC(WorkspaceLogic),
)(WorkspaceUI);