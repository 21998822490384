import { createContext } from 'preact';
import { useContext, useState } from 'preact/hooks';

export const modifiableContextFactory = (initialValue = undefined, useStateToUse = useState) => {
    const ModifiableContext = createContext();

    const useModifiableContext = () => useContext(ModifiableContext);

    const ModifiableProvider = ({ children }) => {
        const context = useStateToUse(initialValue);
        return <ModifiableContext.Provider value={context}>
            {children}
        </ModifiableContext.Provider>;
    };
    return {
        ModifiableContext,
        useModifiableContext,
        ModifiableProvider,
    };
};