import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';
import { useState, useEffect, useMemo, useCallback } from 'preact/hooks';
import groupBy from 'lodash/groupBy';
import isMonday from 'date-fns/isMonday';
import isTuesday from 'date-fns/isTuesday';
import isWednesday from 'date-fns/isWednesday';
import isThursday from 'date-fns/isThursday';
import isFriday from 'date-fns/isFriday';
import isSaturday from 'date-fns/isSaturday';
import isSunday from 'date-fns/isSunday';
import isBefore from 'date-fns/isBefore';
import addMinutes from 'date-fns/addMinutes';
import startOfToday from 'date-fns/startOfToday';
import isAfter from 'date-fns/isAfter';
import isToday from 'date-fns/isToday';
import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import { faMagic, faClock, faEllipsisH, faArchive, faCaretSquareRight, faCaretSquareDown, faCircle, faPlayCircle, faEnvelope, faPauseCircle, faTimesCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { compose, coalesce, guard } from 'src/lib/tools';
import useRefresh from 'src/hooks/refresh';
import useClient from 'src/hooks/client';
import { Loading } from 'src/components/loading';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';
import { CampaignMenu } from 'src/components/fax/campaign-menu';

const testStateIcons = {
    0: { icon: faClock, color: 'blue' },
    1: { icon: faPlayCircle, color: 'blue' },
    2: { icon: faPlayCircle, color: 'blue' },
    3: { icon: faCircle, color: 'green' },
    4: { icon: faCircle, color: 'red' },
    5: { icon: faCircle, color: 'red' },
    6: { icon: faCircle, color: 'green' },
    7: { icon: faEnvelope, color: 'green' },
    scheduled: { icon: faClock, color: 'blue' },
    waiting: { icon: faPlayCircle, color: 'blue' },
    sending: { icon: faPlayCircle, color: 'blue' },
    delivered: { icon: faCircle, color: 'green' },
    error: { icon: faCircle, color: 'red' },
    canceled: { icon: faCircle, color: 'red' },
    deliveredEmail: { icon: faEnvelope, color: 'green' },
};

export const getIconConf = cmp => {
    switch (cmp.state) {
        case 'new':
            return { icon: faCircle, color: 'blue' };
        case 'waitingSignature':
            return coalesce(testStateIcons[cmp.lastTestState], { icon: faCircle, color: 'yellow' });
        case 'scheduled':
            return { icon: faClock, color: 'blue' };
        case 'waitingNextSlot':
            return { icon: faClock, color: 'blue' };
        case 'sleeping':
            return { icon: faPauseCircle, color: 'yellow' };
        case 'sending':
            return { icon: faPlayCircle, color: 'green' };
        case 'retrying':
            return { icon: faCircle, color: 'blue' };
        case 'finishing':
            return { icon: faCircle, color: 'blue' };
        case 'finished':
            return { icon: faCircle, color: 'blue' };
        case 'storing':
            return { icon: faCircle, color: 'blue' };
        case 'unavailableCredit':
            return { icon: faCircle, color: 'red' };
        case 'stored':
            return { icon: faArchive, color: 'blue' };
        default:
            return { icon: faTimesCircle, color: 'red' };
    }
};

export const getMention = (cmp, I18n, dateOptions) => {
    const now = new Date();
    switch (cmp.state) {
        case 'scheduled':
            if (cmp.sendDate) {
                return I18n.t('Envois : %{date}', {
                    date: isToday(cmp.sendDate) ?
                        format(cmp.sendDate, 'p', dateOptions) :
                        formatRelative(cmp.sendDate, now, dateOptions),
                });
            } break;
        case 'waitingNextSlot':
            if (isMonday(now) && !cmp.planningAllowMonday)
                return I18n.t('Pas d\'envois le lundi');
            if (isTuesday(now) && !cmp.planningAllowTuesday)
                return I18n.t('Pas d\'envois le mardi');
            if (isWednesday(now) && !cmp.planningAllowWednesday)
                return I18n.t('Pas d\'envois le mercredi');
            if (isThursday(now) && !cmp.planningAllowFriday)
                return I18n.t('Pas d\'envois le jeudi');
            if (isFriday(now) && !cmp.planningAllowFriday)
                return I18n.t('Pas d\'envois le vendredi');
            if (isSaturday(now) && !cmp.planningAllowSaturday)
                return I18n.t('Pas d\'envois le samedi');
            if (isSunday(now) && !cmp.planningAllowSunday)
                return I18n.t('Pas d\'envois le dimanche');
            if (cmp.maxDelivreredAllowedPerDay && cmp.maxDelivreredAllowedPerDay <= cmp.countRecipientsDeliveredToday)
                return I18n.t(
                    {
                        one: 'Pas plus de 1 abouti par jours',
                        other: 'Pas plus de %{count} aboutis par jours',
                    },
                    { count: cmp.maxDelivreredAllowedPerDay },
                );
            if (cmp.maxDelivreredAllowedPerHour && cmp.maxDelivreredAllowedPerHour <= cmp.countRecipientsDeliveredThisHour)
                return I18n.t(
                    {
                        one: 'Pas plus de 1 abouti par heure',
                        other: 'Pas plus de %{count} aboutis par heure',
                    },
                    { count: cmp.maxDelivreredAllowedPerHour },
                );
            if (isBefore(now, addMinutes(startOfToday(), cmp.planningStartHour)))
                return I18n.t(
                    'Pas d\'envois avant %{date}',
                    { date: format(addMinutes(startOfToday(), cmp.planningStartHour), 'p', dateOptions) },
                );
            if (isAfter(now, addMinutes(startOfToday(), cmp.planningStopHour)))
                return I18n.t(
                    'Pas d\'envois aprés %{date}',
                    { date: format(addMinutes(startOfToday(), cmp.planningStopHour), 'p', dateOptions) },
                );
            break;
    }
};

export const CampaignViewUI = ({ campaign, campaignId, name, color, icon, mention, isShowingMenu, showMenu, hideMenu }) => (
    <li class="flex -row gap-medium roll-over roll-over-trigger">
        <Link class="-fill clickable flex -row  -X-center gap-medium txt-ellipsis" activeClassName="txt-accent" href={`/fax/campaign/${campaignId}`} style={{ width: '100%' }}>
            <Icon fixedWidth style={{ color }} icon={icon} />
            <span class="-fill flex -column -X-stretch gap-small txt-ellipsis">
                <span class="txt-ellipsis" title={name}>{name}</span>
                {guard(mention) &&
                    <span class="-fill flex -row -start gap-medium">
                        <Icon class="padding-small" icon={faExclamation} />
                        <span style={{ fontSize: '.8em' }} class="padding-small">{mention}</span>
                    </span>
                }
            </span>
        </Link>

        <button class="clickable roll-over-triggered" onClick={showMenu}>
            <Icon fixedWidth icon={faEllipsisH} />
        </button>
        {guard(isShowingMenu) &&
            <CampaignMenu onDismiss={hideMenu} campaign={campaign} />
        }
    </li>
);

export const CampaignViewLogic = ({ I18n, dateOptions, campaign, ...props }) => {
    const { campaignId, name } = coalesce(campaign, {});
    const { icon, color } = getIconConf(campaign);
    const mention = getMention(campaign, I18n, dateOptions);
    const [isShowingMenu, setIsShowingMenu] = useState(false);
    const showMenu = useCallback(() => setIsShowingMenu(true), [setIsShowingMenu]);
    const hideMenu = useCallback(() => setIsShowingMenu(false), [setIsShowingMenu]);
    return { ...props, campaign, campaignId, name, color, icon, mention, dateOptions, isShowingMenu, showMenu, hideMenu };
};

export const CampaignView = compose(
    I18nHOC(),
    LogicHOC(CampaignViewLogic),
)(CampaignViewUI);

export const FolderViewUI = ({ I18n, list, title, isOpen, toggleOpen }) => (
    guard(list && list.length) &&
    <Fragment>
        <dt class="clickable sub-title flex -row  -X-center gap-medium" onClick={toggleOpen}>
            <Icon fixedWidth icon={isOpen ? faCaretSquareDown : faCaretSquareRight} />
            <span class="-fill">{I18n.t('%{title} (%{count})', { title, count: list.length })}</span>
        </dt>

        {guard(isOpen) &&
            <dd><ul class="flex -column -X-stretch gap-medium" style={{ marginLeft: '1rem' }}>
                {list.map(campaign =>
                    <CampaignView key={campaign.campaignId} campaign={campaign} />)}
            </ul></dd>
        }
    </Fragment>
);

export const FolderViewLogic = ({ list, title, isOpenAtStart = false, ...props }) => {
    const [isOpen, setIsOpen] = useState(isOpenAtStart);
    const toggleOpen = useCallback(() => setIsOpen(v => !v), [setIsOpen]);

    return { ...props, list, title, isOpen, toggleOpen };
};

export const FolderView = compose(
    LogicHOC(FolderViewLogic),
    I18nHOC(),
)(FolderViewUI);

export const ArchiveFolderViewUi = ({ isLoading, title, isOpen, toggleOpen, listByDates }) => (
    <Fragment>
        <dt class="clickable sub-title flex -row -X-center gap-medium" onClick={toggleOpen}>
            <Icon fixedWidth icon={isOpen ? faCaretSquareDown : faCaretSquareRight} />
            <span class="-fill">{title}</span>
        </dt>

        {guard(isOpen) &&
            <Fragment>
                {isLoading && <Loading />}

                {guard(!isLoading && listByDates) &&
                    <dd><ul class="flex -column -X-stretch gap-medium" style={{ marginLeft: '1rem' }}>
                        {Object.entries(listByDates).map(([date, lst]) =>
                            <FolderView key={date} list={lst} title={date} />)}
                    </ul></dd>
                }
            </Fragment>
        }
    </Fragment>
);

export const ArchiveFolderViewLogic = ({ dateOptions, account, title, ...props }) => {
    const client = useClient();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const { accountId } = account;

    const listByDates = useMemo(() => groupBy(list, cmp => format(cmp.archivedDate, 'LLLL yyyy', dateOptions)), [list, dateOptions]);

    const toggleOpen = useCallback(() => setIsOpen(v => !v), [setIsOpen]);

    useEffect(() => (async () => {
        if (!isOpen) return;
        setIsLoading(true);
        const campaigns = await client.fax.campaign.enum_by_state([accountId], [12]);
        setIsLoading(false);
        setList(campaigns);
    })(), [isOpen, accountId, client, setList]);


    return { ...props, isLoading, title, isOpen, toggleOpen, listByDates };
};

export const ArchiveFolderView = compose(
    I18nHOC(),
    LogicHOC(ArchiveFolderViewLogic),
)(ArchiveFolderViewUi);

export const CampaignsPanelUi = ({ I18n, onCreateNewClick, account, noCredit, newCampaigns, toValidate, scheduled, sleeping, sending, retrying, finishing, finished, storing }) => (
    <div>
        <div class="flex -column gap-medium padding-medium">
            <button class="button round" onClick={onCreateNewClick}>
                <Icon fixedWidth icon={faMagic} />
                <span>{I18n.t('créer une campagne')}</span>
            </button>

            <dl class="display-contents">
                <FolderView list={noCredit} title={I18n.t('Campagnes en attente de crédits')} isOpenAtStart />
                <FolderView list={newCampaigns} title={I18n.t('Nouvelles campagnes')} isOpenAtStart />
                <FolderView list={toValidate} title={I18n.t('Campagnes à valider')} isOpenAtStart />
                <FolderView list={scheduled} title={I18n.t('Campagnes programmées')} />
                <FolderView list={sleeping} title={I18n.t('Campagnes en pause')} />
                <FolderView list={sending} title={I18n.t('Campagnes en cours')} isOpenAtStart />
                <FolderView list={retrying} title={I18n.t('Campagnes en relance')} />
                <FolderView list={finishing} title={I18n.t('Campagnes en finition')} />
                <FolderView list={finished} title={I18n.t('Campagnes récentes')} />
                <FolderView list={storing} title={I18n.t('Campagnes en archivage')} />
                <ArchiveFolderView account={account} title={I18n.t('Campagnes archivées')} />
            </dl>
        </div>
    </div>
);

const extract = (lst, states) => lst.filter(cmp => states.includes(cmp.state));

export const CampaignsPanelLogic = ({ I18n, account, ...props }) => {
    const client = useClient();
    const { accountId } = coalesce(account, {});

    const onCreateNewClick = useCallback(async () => {
        const cmp = await client.fax.campaign.new(accountId, I18n.t('Nouvelle campagne'));
        route(`/fax/campaign/${cmp.campaignId}/wizzard`);
    }, [I18n, client, accountId]);

    const [list = []] = useRefresh(() => client.fax.campaign.enum_by_state([accountId], [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13]), [accountId, client]);
    const newCampaigns = useMemo(() => extract(list, ['new']), [list]);
    const toValidate = useMemo(() => extract(list, ['waitingSignature']), [list]);
    const scheduled = useMemo(() => extract(list, ['scheduled', 'waitingNextSlot']), [list]);
    const sleeping = useMemo(() => extract(list, ['sleeping']), [list]);
    const sending = useMemo(() => extract(list, ['sending']), [list]);
    const retrying = useMemo(() => extract(list, ['retrying']), [list]);
    const finishing = useMemo(() => extract(list, ['finishing']), [list]);
    const finished = useMemo(() => extract(list, ['finished']), [list]);
    const storing = useMemo(() => extract(list, ['storing']), [list]);
    const noCredit = useMemo(() => extract(list, ['unavailable_credit']), [list]);

    return { ...props, onCreateNewClick, account, noCredit, newCampaigns, toValidate, scheduled, sleeping, sending, retrying, finishing, finished, storing };
};

export const CampaignsPanel = compose(
    I18nHOC(),
    LogicHOC(CampaignsPanelLogic),
    I18nHOC(),
)(CampaignsPanelUi);