import { h } from 'preact';
import { LogicHOC } from 'src/components/logic-hoc';
import { compose } from 'src/lib/tools';
import { useCallback } from 'preact/hooks';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';

export const NumberInputUI = ({ value, min, max, onInput, ...props }) => (
    <input
        {...props}
        type="number"
        value={isNumber(value) ? value : null}
        min={isNumber(min) ? min : null}
        max={isNumber(max) ? max : null}
        onInput={onInput}
    />
);

export const NumberInputLogic = ({ onSet, ...props }) => ({
    ...props,
    onInput: useCallback(({ target: { value } }) => onSet(!isNil(value) ? Number(value) : null), [onSet]),
});

export const NumberInput = compose(
    LogicHOC(NumberInputLogic),
)(NumberInputUI);

export default NumberInput;