import 'chartist/dist/scss/chartist.scss';
import './style.less';

import { h } from 'preact';
import { PopupRoot } from 'src/components/popup';
import { ModalRoot } from 'src/components/dialog';
import { SessionManagement } from 'src/components/app/session-management';
import { I18nProvider } from 'src/hooks/i18n';
import { SessionProvider } from 'src/hooks/session';
import { AccountProvider } from 'src/hooks/account';
import { PagesHistoryProvider } from 'src/hooks/pages-history';
import { App } from 'src/components/app';

export const Root = () => (
    <I18nProvider>
        <PagesHistoryProvider>
            <SessionProvider>
                <AccountProvider>
                    <SessionManagement>
                        <ModalRoot />
                        <PopupRoot />
                        <App />
                    </SessionManagement>
                </AccountProvider>
            </SessionProvider>
        </PagesHistoryProvider>
    </I18nProvider>
);

export default Root;