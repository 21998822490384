import { compose } from 'src/lib/tools';
import { ContextMenuUI } from 'src/components/popup';
import { LogicHOC } from 'src/components/logic-hoc';
import { useCampaignMenuEntries } from 'src/hooks/fax/campaign-menu';

export const CampaignMenuLogic = ({ campaign, onDismiss, ...props }) => {
    const menuEntries = useCampaignMenuEntries(campaign, onDismiss);
    return { ...props, menuEntries, onDismiss };
};

export const CampaignMenu = compose(
    LogicHOC(CampaignMenuLogic),
)(ContextMenuUI);