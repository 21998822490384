import { h, Fragment } from 'preact';
import { compose, guard } from 'src/lib/tools';
import { usePage } from 'src/hooks/pages-history';
import { LogicHOC } from './logic-hoc';
import classNames from 'classnames';

export const PageTitleUI = ({ style, class: className, title, children }) => (
    <div class={classNames(className, 'col-full flex -row -center -X-center gap-medium')} style={style}>
        {guard(title) && <h1 class="-fill self-center self-X-center title">{title}</h1>}
        {children}
    </div>
);

export const PageTitleLogic = ({ pageId, icon, title, ...props }) => {
    usePage(pageId, icon, title);
    return { ...props, title };
};

export const PageTitle = compose(
    LogicHOC(PageTitleLogic),
)(PageTitleUI);

export const Section = ({ title, children }) => (
    <fieldset class="display-contents">
        {guard(title) && <legend class="sub-title property-title">{title}</legend>}
        {children}
    </fieldset>
);

export const EntryLine = ({ style, class: className, htmlFor, children }) => (
    <label
        style={style}
        htmlFor={htmlFor}
        class={classNames(className, 'property-line flex -row -start -X-center gap-medium')}
    >{children}</label>
);

export const EntryInput = ({ style, class: className, title, children, htmlFor }) => (
    <Fragment>
        {guard(title) && <label htmlFor={htmlFor} class="property-key self-X-center flex -row -X-center gap-medium">{title}</label>}
        <span
            style={style}
            class={classNames(className, title ? 'property-value' : 'property-line', 'self-X-stretch input round')}
        >{children}</span>
    </Fragment>
);

export const EntryDoc = ({ children }) => (
    <div class="col-full doc margin-auto padding-large pre-line">{children}</div>
);