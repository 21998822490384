const getDefault = async o => (await o).default;

const routes = {
    'infrastructure.profile': () => getDefault(import(/* webpackChunkName: 'infrastructure.profile' */'src/routes/infrastructure/profile')),
    'fax.campaigns': () => getDefault(import(/* webpackChunkName: 'fax.campaigns' */'src/routes/fax/campaigns')),
    'fax.campaign': () => getDefault(import(/* webpackChunkName: 'fax.campaign' */'src/routes/fax/campaign')),
    'fax.campaign.rename': () => getDefault(import(/* webpackChunkName: 'fax.campaign.rename' */'src/routes/fax/campaign/rename')),
    'fax.campaign.duplicate': () => getDefault(import(/* webpackChunkName: 'fax.campaign.duplicate' */'src/routes/fax/campaign/duplicate')),
    'fax.campaign.constraints': () => getDefault(import(/* webpackChunkName: 'fax.campaign.constraints' */'src/routes/fax/campaign/constraints')),
    'fax.campaign.tests': () => getDefault(import(/* webpackChunkName: 'fax.campaign.tests' */'src/routes/fax/campaign/tests')),
    'fax.campaign.blacklists': () => getDefault(import(/* webpackChunkName: 'fax.campaign.blacklists' */'src/routes/fax/campaign/blacklists')),
    'fax.campaign.priority': () => getDefault(import(/* webpackChunkName: 'fax.campaign.priority' */'src/routes/fax/campaign/priority')),
    'fax.campaign.document': () => getDefault(import(/* webpackChunkName: 'fax.campaign.document' */'src/routes/fax/campaign/document')),
    'fax.blacklist': () => getDefault(import(/* webpackChunkName: 'fax.blacklist' */'src/routes/fax/blacklist')),
    'fax.history': () => getDefault(import(/* webpackChunkName: 'fax.history' */'src/routes/fax/history')),
    'fax.campaign.resume': () => getDefault(import(/* webpackChunkName: 'fax.campaign.resume' */'src/routes/fax/campaign/resume')),
    'fax.campaign.suspend': () => getDefault(import(/* webpackChunkName: 'fax.campaign.suspend' */'src/routes/fax/campaign/suspend')),
    'fax.campaign.archivate': () => getDefault(import(/* webpackChunkName: 'fax.campaign.archivate' */'src/routes/fax/campaign/archivate')),
    'fax.campaign.delete': () => getDefault(import(/* webpackChunkName: 'fax.campaign.delete' */'src/routes/fax/campaign/delete')),
    'fax.campaign.terminate-current-plan': () => getDefault(import(/* webpackChunkName: 'fax.campaign.terminate-current-plan' */'src/routes/fax/campaign/terminate-current-plan')),
    'fax.campaign.terminate': () => getDefault(import(/* webpackChunkName: 'fax.campaign.terminate' */'src/routes/fax/campaign/terminate')),
    'fax.campaign.validate': () => getDefault(import(/* webpackChunkName: 'fax.campaign.validate' */'src/routes/fax/campaign/validate')),
    'fax.campaign.owner': () => getDefault(import(/* webpackChunkName: 'fax.campaign.owner' */'src/routes/fax/campaign/owner')),
    'fax.campaign.plans': () => getDefault(import(/* webpackChunkName: 'fax.campaign.plans' */'src/routes/fax/campaign/plans')),
    'fax.campaign.add-plan': () => getDefault(import(/* webpackChunkName: 'fax.campaign.add-plans' */'src/routes/fax/campaign/add-plan')),
    'fax.campaign.report': () => getDefault(import(/* webpackChunkName: 'fax.campaign.report' */'src/routes/fax/campaign/report')),
    'fax.campaign.countries': () => getDefault(import(/* webpackChunkName: 'fax.campaign.countries' */'src/routes/fax/campaign/countries')),
    'fax.campaign.wizzard': () => getDefault(import(/* webpackChunkName: 'fax.campaign.wizzard' */'src/routes/fax/campaign/wizzard')),
    'fax.shop': () => getDefault(import(/* webpackChunkName: 'fax.shop' */'src/routes/fax/shop')),
    'infrastructure.welcome': () => getDefault(import(/* webpackChunkName: 'infrastructure.welcome' */'src/routes/infrastructure/welcome')),
};

export default component => routes[component];