import { useState, useEffect } from 'preact/hooks';

export function useDebounce (value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(setDebouncedValue, delay, value);
        return () => clearTimeout(handler);
    }, [delay, value]);
    return debouncedValue;
}

export default useDebounce;