import { createContext } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { coalesce } from 'src/lib/tools';
import I18n from 'i18nline';
import countries from 'i18n-iso-countries';
import root from 'window-or-global';
import { intersection } from 'lodash';

export const supportedCultures = {
    'fr-FR': {
        display: 'France',
        flag: '🇫🇷',
    },
    'fr-BE': {
        display: 'Belgique',
        flag: '🇧🇪',
    },
    'fr-LU': {
        display: 'Luxembourg',
        flag: '🇱🇺',
    },
    'fr-CH': {
        display: 'Suisse',
        flag: '🇨🇭',
    },
    'fr-MC': {
        display: 'Monaco',
        flag: '🇲🇨',
    },
    'fr-CA': {
        display: 'Canada (Français)',
        flag: '🇨🇦',
    },
    'en-CA': {
        display: 'Canada (English)',
        flag: '🇨🇦',
    },
    'en-GB': {
        display: 'Great Britain',
        flag: '🇬🇧',
    },
    'en-US': {
        display: 'United State of America',
        flag: '🇺🇸',
    },
};

export const defaultCulture = 'fr-FR';

export const getI18nLocale = async locale => {
    switch (locale) {
        default: return import(/* webpackChunkName: 'i18n.fr' */ 'src/i18n/fr.json');
        case 'fr': return import(/* webpackChunkName: 'i18n.fr' */ 'src/i18n/fr.json');
        case 'en': return import(/* webpackChunkName: 'i18n.en' */ 'src/i18n/en.json');
    }
};

export const cultureLoaders = {
    currentCulture: culture => culture,

    currentLanguage: culture => culture.split('-')[0],

    currentCountry: culture => culture.split('-')[1],

    dateOptions: async culture => {
        switch (culture) {
            default: return {
                locale: (await import(/* webpackChunkName: 'i18n.fr' */'date-fns/locale/fr')).default,
            };

            case 'fr-FR': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr' */'date-fns/locale/fr')).default,
            };

            case 'fr-BE': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr' */'date-fns/locale/fr')).default,
            };

            case 'fr-LU': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr' */'date-fns/locale/fr')).default,
            };

            case 'fr-CH': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr-CH' */'date-fns/locale/fr-CH')).default,
            };

            case 'fr-MC': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr' */'date-fns/locale/fr')).default,
            };

            case 'fr-CA': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr-CA' */'date-fns/locale/fr-CA')).default,
            };

            case 'en-CA': return {
                locale: (await import(/* webpackChunkName: 'i18n.fr-CA' */'date-fns/locale/en-CA')).default,
            };

            case 'en-GB': return {
                locale: (await import(/* webpackChunkName: 'i18n.en-GB' */'date-fns/locale/en-GB')).default,
            };

            case 'en-US': return {
                locale: (await import(/* webpackChunkName: 'i18n.en-US' */'date-fns/locale/en-US')).default,
            };
        }
    },

    countriesLocale: async locale => {
        switch (locale) {
            default: {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'fr-FR': {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'fr-BE': {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'fr-LU': {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'fr-CH': {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'fr-MC': {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'fr-CA': {
                const locales = await import(/* webpackChunkName: 'i18n.fr' */ 'i18n-iso-countries/langs/fr.json');
                countries.registerLocale(locales);
                return 'fr';
            }

            case 'en-CA': {
                const locales = await import(/* webpackChunkName: 'i18n.en' */ 'i18n-iso-countries/langs/en.json');
                countries.registerLocale(locales);
                return 'en';
            }

            case 'en-GB': {
                const locales = await import(/* webpackChunkName: 'i18n.en' */ 'i18n-iso-countries/langs/en.json');
                countries.registerLocale(locales);
                return 'en';
            }

            case 'en-US': {
                const locales = await import(/* webpackChunkName: 'i18n.en' */ 'i18n-iso-countries/langs/en.json');
                countries.registerLocale(locales);
                return 'en';
            }
        }
    },

    currentLocale: async locale => {
        switch (locale) {
            default: return 'fr';
            case 'fr-FR': return 'fr';
            case 'fr-BE': return 'fr';
            case 'fr-LU': return 'fr';
            case 'fr-CH': return 'fr';
            case 'fr-MC': return 'fr';
            case 'fr-CA': return 'fr';
            case 'en-CA': return 'en';
            case 'en-GB': return 'en';
            case 'en-US': return 'en';
        }
    },

    I18n: async locale => {
        switch (locale) {
            default: I18n.changeLocale('fr'); break;
            case 'fr-FR': I18n.changeLocale('fr'); break;
            case 'fr-BE': I18n.changeLocale('fr'); break;
            case 'fr-LU': I18n.changeLocale('fr'); break;
            case 'fr-CH': I18n.changeLocale('fr'); break;
            case 'fr-MC': I18n.changeLocale('fr'); break;
            case 'fr-CA': I18n.changeLocale('fr'); break;
            case 'en-CA': I18n.changeLocale('en'); break;
            case 'en-GB': I18n.changeLocale('en'); break;
            case 'en-US': I18n.changeLocale('en'); break;
        }
        return { t: (...args) => I18n.t(...args) };
    },

    phoneNumberDefaultCountry: async locale => {
        switch (locale) {
            default: return 'FR';
            case 'fr-FR': return 'FR';
            case 'fr-BE': return 'BE';
            case 'fr-LU': return 'LU';
            case 'fr-CH': return 'CH';
            case 'fr-MC': return 'MC';
            case 'fr-CA': return 'CA';
            case 'en-MC': return 'CA';
            case 'en-GB': return 'GB';
            case 'en-US': return 'US';
        }
    },
};

I18n.supportedLocales = ['fr', 'en'];
I18n.defaultLocale = 'fr';
I18n.import = getI18nLocale;

export const I18nContext = createContext([{ I18n: { t: () => null } }, () => null]);

const getDefaultCulture = () => {
    try {
        const json = root.localStorage.getItem('defaultCulture');
        if (json) {
            const { culture } = JSON.parse(json);
            if (Object.keys(supportedCultures).includes(culture)) return culture;
        }
    } catch { }

    if (navigator.languages !== undefined)
        return coalesce(intersection(navigator.languages, supportedCultures)[0], defaultCulture);

    return defaultCulture;
};

const setDefaultCulture = culture => {
    root.localStorage.setItem('defaultCulture', JSON.stringify({ culture }));
};


export const useI18n = () => {
    return useContext(I18nContext);
};

export const I18nProvider = ({ children }) => {
    const [ctx, setCurrentCultureState] = useState(() => {
        const setCurrentCulture = async culture => {
            setDefaultCulture(culture);
            Object.entries(cultureLoaders).forEach(async ([prop, loader]) => {
                const value = await loader(culture);
                setCurrentCultureState(v => ({ ...v, [prop]: value }));
            });
        };

        setTimeout(() => setCurrentCulture(getDefaultCulture()), 0);
        return {
            I18n: { t: () => null },
            setCurrentCulture,
        };
    });

    return <I18nContext.Provider value={ctx}>
        {children}
    </I18nContext.Provider>;
};

export default useI18n;