import { useEffect, useCallback, useMemo } from 'preact/hooks';
import { route, getCurrentUrl } from 'preact-router';
import root from 'window-or-global';
import { coalesce } from 'src/lib/tools';
import { usePersistedState } from 'src/hooks/local-storage';
import { modifiableContextFactory } from 'src/lib/modifiable-context';

export const {
    ModifiableContext: PagesHistoryContext,
    ModifiableProvider: PagesHistoryProvider,
    useModifiableContext: usePagesHistoryCore,
} = modifiableContextFactory([], initial => usePersistedState('history', initial));

export const usePagesHistory = () => {
    const [history, setHistory] = usePagesHistoryCore();
    const href = getCurrentUrl();

    const remove = useCallback(page => {
        setHistory(history.filter(p => p.pageId !== page.pageId));
        if (page.href === href) {
            const [{ href: newHRef } = {}] = history.filter(p => p.href !== href);
            route(coalesce(newHRef, '/welcome'));
        }
    }, [setHistory, href, history]);

    return {
        history,
        remove,
    };
};


export const usePage = (pageId, icon, title) => {
    const href = getCurrentUrl();
    const [, setHistory] = usePagesHistoryCore();
    const page = useMemo(() => ({
        pageId, title, icon, href,
    }), [pageId, title, icon, href]);

    useEffect(() => {
        root.document.title = page.title;
        setHistory(hist => {
            const idx = hist.findIndex(p => p.pageId === page.pageId);
            if (idx >= 0)
                return hist.map((p, i) => i === idx ? { ...p, ...page } : p);
            return [...hist.filter(p => p.pageId !== page.pageId), page];
        });
    }, [setHistory, page]);
};

export default usePage;