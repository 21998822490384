import { h } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import { faUser, faLock, faPlug } from '@fortawesome/free-solid-svg-icons';

import { compose } from 'src/lib/tools';
import useClient from 'src/hooks/client';
import useSession from 'src/hooks/session';
import useInput from 'src/hooks/input';
import useId from 'src/hooks/id-generator';
import { Icon } from 'src/components/icon';
import { Modal } from 'src/components/dialog';
import { I18nHOC } from 'src/components/i18n-hoc';
import { LogicHOC } from 'src/components/logic-hoc';

export const AuthModalUI = ({ I18n, loginId, authenticate, login, onLoginChanged, passwordId, password, onPasswordChanged }) => (
    <Modal title={I18n.t('Connexion à la plateforme')} class="flex -column -X-stretch gap-medium">
        <form onSubmit={authenticate} style={{ fontSize: '2rem' }} class="display-contents">
            <label htmlFor={loginId} class="input round">
                <Icon fixedWidth icon={faUser} />
                <span class="-fill flex"><input id={loginId} autoFocus class="-fill margin-auto" value={login} onInput={onLoginChanged} type="text" placeholder={I18n.t('Identifiant')} /></span>
            </label>

            <label htmlFor={passwordId} class="input round">
                <Icon fixedWidth icon={faLock} />
                <span fill class="-fill flex"><input id={passwordId} class="-fill margin-auto" value={password} onInput={onPasswordChanged} type="password" placeholder={I18n.t('Mot de passe')} /></span>
            </label>

            <button class="button round" type="submit" >
                <Icon fixedWidth icon={faPlug} />
                <span>{I18n.t('Se connecter')}</span>
            </button>
        </form>
    </Modal>
);

export const AuthModalLogic = ({ ...props }) => {
    const client = useClient();
    const [, setSession] = useSession();
    const [login, _setLoginState] = useState('');
    const onLoginChanged = useInput(_setLoginState);
    const [password, _setPasswordState] = useState('');
    const onPasswordChanged = useInput(_setPasswordState);
    const [loginId, passwordId] = useId(2);

    const authenticate = useCallback(async e => {
        e.preventDefault();
        const session = await client.server.session.connect_user(login, password, 'React-Manager');
        setSession(session);
    }, [login, password, client, setSession]);

    return { ...props, loginId, passwordId, authenticate, login, onLoginChanged, password, onPasswordChanged };
};

export const AuthModal = compose(
    LogicHOC(AuthModalLogic),
    I18nHOC(),
)(AuthModalUI);