import { h } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import { faPowerOff, faCogs, faSearch } from '@fortawesome/free-solid-svg-icons';
import { compose, guard } from 'src/lib/tools';
import useClient from 'src/hooks/client';
import useSession from 'src/hooks/session';
import useAccount from 'src/hooks/account';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';
import { SearchAccountModal } from 'src/components/infrastructure/search-account-modal';
import { HierarchyList } from './hierarchy-list';

const AccountBarUI = ({ I18n, style, searchDialogShown, hideSearchDialog, onAccountSelected, showSearchDialog, account, onDisconnectClick }) => (
    <header style={style} class="flex -row -X-stretch gap-medium">
        <nav class="-fill flex -row -X-stretch round bg-light gap-medium" >
            <a class="button" onClick={showSearchDialog}>
                <Icon fixedWidth style={{ fontSize: '1.8rem' }} icon={faSearch} />
                <span style={{ fontSize: '.8rem', whiteSpace: 'pre-line' }}>{I18n.t('Rechercher\n un compte')}</span>
            </a>

            <HierarchyList />

            <a class="button" href={`/profile/${account.accountId}`}>
                <Icon fixedWidth style={{ fontSize: '1.8rem' }} icon={faCogs} />
                <span style={{ fontSize: '.8rem', whiteSpace: 'pre-line' }}>{I18n.t('Detail du\n compte')}</span>
            </a>
        </nav>

        <a style={{ fontSize: '1.8rem' }} class="button round" onClick={onDisconnectClick}>
            <Icon fixedWidth icon={faPowerOff} />
        </a>

        {guard(searchDialogShown) &&
            <SearchAccountModal onDismiss={hideSearchDialog} onAccountSelected={onAccountSelected} />
        }
    </header>
);

const AccountBarLogic = ({ ...props }) => {
    const client = useClient();
    const [, setSession] = useSession();
    const [account = {}, setAccount] = useAccount();
    const [searchDialogShown, setShowSearchDialog] = useState(false);

    const showSearchDialog = useCallback(() => setShowSearchDialog(true), [setShowSearchDialog]);
    const hideSearchDialog = useCallback(() => setShowSearchDialog(false), [setShowSearchDialog]);

    const onAccountSelected = useCallback(acc => {
        setAccount(acc);
        hideSearchDialog();
    }, [hideSearchDialog, setAccount]);

    const onDisconnectClick = useCallback(async () => {
        const session = await client.server.session.disconnect_user();
        setSession(session);
    }, [client, setSession]);

    return { ...props, searchDialogShown, hideSearchDialog, onAccountSelected, showSearchDialog, account, onDisconnectClick };
};

export const AccountBar = compose(
    LogicHOC(AccountBarLogic),
    I18nHOC(),
)(AccountBarUI);