import { h } from 'preact';
import { useRef, useCallback } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import classNames from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { guard } from 'src/lib/tools';
import { Icon } from './icon';

let modalRoot = null;

export const ModalRoot = () => (
    <div id="ModalRoot" ref={r => modalRoot = r} />
);

export const Modal = ({ title, onDismiss, class: className, children, ...props }) => {
    const ModalBack = useRef();
    const onDismissCB = useCallback(e => {
        if (onDismiss && e.srcElement === ModalBack.current) onDismiss(e);
    }, [ModalBack, onDismiss]);

    return createPortal(
        <div ref={ModalBack} class="centerer" onClick={onDismissCB}>
            <dialog class={classNames('round gap-medium padding-medium bg-light', className)} {...props} >
                {guard(title || onDismiss) &&
                    <div class="col-full flex -row -center -X-center">
                        {guard(title) && <h1 class="-fill self-center self-X-center title">{title}</h1>}
                        {guard(onDismiss) && <Icon fixedWidth class="clickable" onClick={onDismiss} style={{ marginLeft: 'auto' }} icon={faTimes} />}
                    </div>
                }
                {children}
            </dialog>
        </div>,
        modalRoot,
    );
};

export default Modal;