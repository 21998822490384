import { useEffect, useState, useRef, useCallback } from 'preact/hooks';

export const useRefresh = (getter, inputs, delay = 10000) => {
    const timeoutHandler = useRef();
    const resolveRefs = useRef([]);
    const [state, setState] = useState();

    const job = useCallback(async d => {
        const currentHandler = timeoutHandler.current;
        const result = await getter();
        if (timeoutHandler.current === currentHandler) {
            setState(result);
            const resolvers = resolveRefs.current;
            resolveRefs.current = [];
            resolvers.forEach(r => r(result));
            if (d !== false) timeoutHandler.current = setTimeout(job, d, d);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resolveRefs, timeoutHandler, ...inputs]);

    useEffect(() => {
        setState(undefined);
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = setTimeout(job, 0, delay);
        return () => {
            clearTimeout(timeoutHandler.current);
            timeoutHandler.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoutHandler, job, delay, ...inputs]);

    const refresh = useCallback(() => {
        if (timeoutHandler.current) {
            clearTimeout(timeoutHandler.current);
            timeoutHandler.current = setTimeout(job, 0, delay);
            return new Promise(resolve => resolveRefs.current.push(resolve));
        }
        return Promise.reject(new Error('Refresher is disposed'));
    }, [resolveRefs, timeoutHandler, job, delay]);

    return [state, setState, refresh, state !== undefined];
};

export default useRefresh;