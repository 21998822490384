import { h, Fragment } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';
import { faFax, faMinusCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { compose, coalesce, guard } from 'src/lib/tools';
import { usePersistedState } from 'src/hooks/local-storage';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';
import { CampaignsPanel } from './campaigns-panel';
import { RedlistPanel } from './redlist-panel';
import { CreditsPanel } from './credits-panel';

const FaxModuleUI = ({
    I18n, style, account, accountId, isTicketAccount,
    campaignIsSelected, selectCampaign,
    blacklistIsSelected, selectBlacklist,
    creditsIsSelected, selectCredits,
}) => (
    guard(accountId) && (
        <article style={style} class="flex -column -X-stretch round gap-small bg-light">
            <button
                class="button -start"
                onClick={selectCampaign}
                style={{ fontSize: '2rem' }}
            >
                <Icon fixedWidth icon={faFax} />
                <span>{I18n.t('Campagne')}</span>
            </button>

            {guard(campaignIsSelected) &&
                <div class="-fill scroll-container flex -column gap-medium">
                    <CampaignsPanel account={account} />
                </div>
            }

            <button
                class="button -start"
                onClick={selectBlacklist}
                style={{ fontSize: '2rem' }}
            >
                <Icon fixedWidth icon={faMinusCircle} />
                <span>{I18n.t('Liste noire')}</span>
            </button>

            {guard(blacklistIsSelected) &&
                <div class="-fill scroll-container flex -column gap-medium">
                    <RedlistPanel account={account} />
                </div>
            }

            {guard(isTicketAccount) &&
                <Fragment>
                    <button
                        class="button -start"
                        onClick={selectCredits}
                        style={{ fontSize: '2rem' }}
                    >
                        <Icon fixedWidth icon={faShoppingCart} />
                        <span>{I18n.t('Crédits')}</span>
                    </button>

                    {guard(creditsIsSelected) &&
                        <div class="-fill scroll-container flex -column gap-medium">
                            <CreditsPanel account={account} />
                        </div>
                    }
                </Fragment>
            }
        </article>
    )
);

const FaxModuleLogic = ({ account, ...props }) => {
    const { accountId, isTicketAccount } = coalesce(account, {});
    const [section, setSection] = usePersistedState('fax.module.section', 'Campaign');
    const selectCampaign = useCallback(() => setSection('Campaign'), [setSection]);
    const selectBlacklist = useCallback(() => setSection('BlackList'), [setSection]);
    const selectCredits = useCallback(() => setSection('Credits'), [setSection]);

    const realSection = useMemo(() => {
        switch (section) {
            case 'Credits':
                if (!isTicketAccount) return 'Campaign';
                break;
        }
        return section;
    }, [section, isTicketAccount]);

    const campaignIsSelected = realSection === 'Campaign';
    const blacklistIsSelected = realSection === 'BlackList';
    const creditsIsSelected = realSection === 'Credits';

    return {
        ...props,
        account,
        accountId,
        isTicketAccount,
        campaignIsSelected,
        selectCampaign,
        blacklistIsSelected,
        selectBlacklist,
        creditsIsSelected,
        selectCredits,
    };
};

export const FaxModule = compose(
    LogicHOC(FaxModuleLogic),
    I18nHOC(),
)(FaxModuleUI);