import { h, Fragment } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { guard, compose, coalesce } from 'src/lib/tools';
import { Loading } from 'src/components/loading';
import { Grid, HeaderCell } from 'src/components/grid';
import { Modal } from 'src/components/dialog';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';
import { useId } from 'src/hooks/id-generator';
import { useAsync } from 'src/hooks/async';
import { useDebounce } from 'src/hooks/debounce';
import { useClient } from 'src/hooks/client';
import { useInput } from 'src/hooks/input';

export const HeaderRowTemplateUI = ({ I18n, getSorter }) => (
    <tr>
        <HeaderCell class="padding-medium" {...getSorter(acc => acc.login)}>{I18n.t('Compte')}</HeaderCell>
        <HeaderCell class="padding-medium" {...getSorter(acc => acc.application)}>{I18n.t('Marque')}</HeaderCell>
    </tr>
);

export const HeaderRowTemplate = compose(
    I18nHOC(),
)(HeaderRowTemplateUI);

export const RowTemplateUI = ({ login, application, onClick }) => (
    <tr onClick={onClick} class="roll-over clickable">
        <td class="padding-medium" title={login}>{login}</td>
        <td class="padding-medium" title={application}>{application}</td>
    </tr>
);

export const RowTemplateLogic = ({ account, selectAccount, ...props }) => {
    const { login, application } = coalesce(account, {});
    const onClick = useCallback(() => selectAccount(account), [selectAccount, account]);
    return { ...props, login, application, onClick };
};

export const RowTemplate = compose(
    LogicHOC(RowTemplateLogic),
)(RowTemplateUI);

export const SearchAccountModalUI = ({ I18n, searchId, state, list, onDismiss, searchText, onSearchTextInput, selectAccount }) => (
    <Modal onDismiss={onDismiss} title={I18n.t('Rechercher un compte')} class="flex -column">
        <label htmlFor={searchId} class="input round" style={{ fontSize: '2rem' }}>
            <Icon fixedWidth icon={faSearch} />
            <span class="-fill centerer"><input id={searchId} autoFocus value={searchText} onInput={onSearchTextInput} type="text" placeholder={I18n.t('Rechercher un compte ...')} /></span>
        </label>

        <div class="flex round border bg-white" style={{ height: '40vh' }}>
            {guard(state === 'idle') &&
                <span class="margin-auto">{I18n.t('Effectuez une recherche pour afficher des résultats')}</span>
            }
            {guard(state === 'loading') &&
                <Loading class="margin-auto" />
            }
            {guard(state === 'success') && <Fragment>
                {guard(!list) &&
                    <span class="margin-auto">{I18n.t('Effectuez une recherche pour afficher des résultats')}</span>
                }
                {guard((list && !list.length)) &&
                    <span class="margin-auto">{I18n.t('Aucun résultat trouvé')}</span>
                }
                {guard(list && list.length) &&
                    <Grid
                        class="-no-border"
                        list={list}
                        headerRowTemplate={getSorter => <HeaderRowTemplate getSorter={getSorter} />}
                        rowTemplate={account => <RowTemplate key={account.accountId} account={account} selectAccount={selectAccount} />}
                    />
                }
            </Fragment>}
        </div>
    </Modal>
);
export const SearchAccountModalLogic = ({ onDismiss, onAccountSelected, ...props }) => {
    const [searchId] = useId();
    const client = useClient();
    const [searchText, _setSearchText] = useState('');
    const onSearchTextInput = useInput(_setSearchText);
    const debouncedSearchText = useDebounce(searchText, 500);
    const [state, list] = useAsync(
        async () => {
            if (debouncedSearchText.length < 3) return null;
            return client.infrastructure.account.find(debouncedSearchText);
        },
        [client, debouncedSearchText],
    );

    const selectAccount = useCallback(acc => {
        if (onAccountSelected) onAccountSelected(acc);
    }, [onAccountSelected]);

    return { ...props, searchId, state, list, onDismiss, searchText, onSearchTextInput, selectAccount };
};

export const SearchAccountModal = compose(
    LogicHOC(SearchAccountModalLogic),
    I18nHOC(),
)(SearchAccountModalUI);