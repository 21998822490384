import { useState, useEffect } from 'preact/hooks';
import root from 'window-or-global';
import { coalesce } from 'src/lib/tools';

export const usePersistedState = (key, initial) => {
    const [value, setValue] = useState(() => {
        const json = root.localStorage.getItem(key);
        const box = json ? JSON.parse(json) : { };
        return coalesce(box.value, initial);
    });

    useEffect(() => {
        root.localStorage.setItem(key, JSON.stringify({ value }));
    }, [key, value]);

    useEffect(() => {
        const handler = ({ key: k, newValue }) => {
            if (k !== key) return;
            setValue(JSON.parse(newValue).value);
        };
        root.addEventListener('storage', handler);
        return () => root.removeEventListener('storage', handler);
    }, [key, setValue]);

    return [value, setValue];
};

export default usePersistedState;