
const compose2 = (f, g) => (...args) => f(g(...args));

export const compose = (...fns) => fns.reduce(compose2);

export const pipe = (...fns) => fns.reduceRight(compose2);

export const invokeOrReturn = (f, v) => typeof f === 'function' ? f(v) : f;

export const coalesce = (...args) => args.reduce((a, v) => a === null || a === undefined ? v : a, null);

export const guard = Boolean;

export const exec = f => f();

export const minmax = (v, min, max) => Math.min(Math.max(v, min), max);