import { useState, useEffect, useCallback, useRef } from 'preact/hooks';

const initialValue = {
    state: 'idle',
};

export const useAsync = (getAsync, inputs) => {
    const [value, setValue] = useState(initialValue);
    const batchRef = useRef(0);
    const refresh = useCallback(async () => {
        const thisBatch = ++batchRef.current;
        setValue({ state: 'loading' });
        try {
            const result = await getAsync();
            if (thisBatch === batchRef.current)
                setValue({ state: 'success', value: result });
        } catch (error) {
            if (thisBatch === batchRef.current)
                setValue({ state: 'error', error: error });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, batchRef, ...inputs]);

    useEffect(() => refresh(), [refresh]);

    return [value.state, value.value, value.error, setValue, refresh];
};

export default useAsync;