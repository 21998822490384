import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { compose, guard } from 'src/lib/tools';
import useAsync from 'src/hooks/async';
import useClient from 'src/hooks/client';
import useAccount from 'src/hooks/account';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';

export const AccountButtonUI = ({ login, onClick }) => (
    <li class="clickable input round" onClick={onClick}>
        <Icon fixedWidth class="-inv-color border-right" icon={faUser} />
        <span class="centerer"><span>{login}</span></span>
    </li>
);

export const AccountButtonLogin = ({ account, setAccount, ...props }) => {
    const onClick = useCallback(() => setAccount(account), [setAccount, account]);
    const { login } = account;
    return { ...props, login, onClick };
};

export const AccountButton = compose(
    LogicHOC(AccountButtonLogin),
)(AccountButtonUI);

export const HierarchyListUI = ({ currentHierarchy, setAccount }) => (
    <ul class="-fill flex -hz -X-center overflow-x-auto nowrap padding-medium gap-medium">
        {guard(currentHierarchy && currentHierarchy.length) && currentHierarchy.map(account =>
            <AccountButton key={account.accountId} account={account} setAccount={setAccount} />)}
    </ul>
);

export const HierarchyListLogic = ({ ...props }) => {
    const client = useClient();
    const [account = {}, setAccount] = useAccount();
    const [, currentHierarchy = [account].filter(i => i)] = useAsync(
        () => client.infrastructure.account.enum_hierarchy(account.accountId),
        [client, account.accountId],
    );

    return { ...props, currentHierarchy, setAccount };
};

export const HierarchyList = compose(
    LogicHOC(HierarchyListLogic),
)(HierarchyListUI);