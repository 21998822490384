import { h } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import { faPlus, faArrowRight, faPen, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { compose, coalesce, guard } from 'src/lib/tools';
import { useClient } from 'src/hooks/client';
import { useInput } from 'src/hooks/input';
import { useId } from 'src/hooks/id-generator';
import { Icon } from 'src/components/icon';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';

export const RedlistPanelUI = ({ I18n, numberId, accountId, number, onNumberInput, blacklistNumber, addNumberFeedback, closeAddNumberFeedback }) => (
    guard(accountId) &&
    <div class="flex -column gap-medium padding-medium">
        <a class="button round" href={`/fax/blacklist/${accountId}`}>
            <Icon fixedWidth icon={faPen} />
            <span>{I18n.t('Editer ma liste noire')}</span>
        </a>

        <form onSubmit={blacklistNumber}>
            <label htmlFor={numberId} class="input round">
                <Icon fixedWidth icon={faPlus} />
                <span class="-fill centerer"><input id={numberId} value={number} onInput={onNumberInput} type="text" placeholder={I18n.t('Ajouter un numéro sur liste noire ...')} /></span>
                <button type="submit" class="-inv-color clickable border-left centerer"><Icon fixedWidth icon={faArrowRight} /></button>
            </label>
        </form>

        {guard(addNumberFeedback) &&
            <div class="flex -row -X-center gap-medium">
                <span class="-fill">{addNumberFeedback}</span>
                <Icon fixedWidth icon={faTimesCircle} onClick={closeAddNumberFeedback} />
            </div>
        }
    </div>
);

export const RedlistPanelLogic = ({ I18n, account, ...props }) => {
    const client = useClient();
    const [numberId] = useId();
    const [number, setNumber] = useState('');
    const [addNumberFeedback, setAddNumberFeedback] = useState();
    const onNumberInput = useInput(setNumber);
    const { accountId } = coalesce(account, {});

    const blacklistNumber = useCallback(async e => {
        e.preventDefault();
        try {
            const numberAdded = await client.fax.account.add_to_personnal_redlist(accountId, number);
            if (numberAdded) {
                setAddNumberFeedback(I18n.t('Le numéro %{number} a été ajouté sur la liste noire.', { number }));
            } else {
                setAddNumberFeedback(I18n.t('Impossible d\'ajouter le numéro %{number} sur la liste noire.', { number }));
            }
        } catch (err) {
            switch (err.code) {
                case 10050:
                    setAddNumberFeedback(I18n.t('Le numéro %{number} est déjà sur la liste noire.', { number }));
                    break;
                default:
                    setAddNumberFeedback(I18n.t('Impossible d\'ajouter le numéro %{number} sur la liste noire.', { number }));
                    break;
            }
        }
        setNumber('');
    }, [number, accountId, client, I18n]);

    const closeAddNumberFeedback = useCallback(() => setAddNumberFeedback(null), [setAddNumberFeedback]);

    return { ...props, numberId, accountId, number, onNumberInput, blacklistNumber, addNumberFeedback, closeAddNumberFeedback };
};

export const RedlistPanel = compose(
    I18nHOC(),
    LogicHOC(RedlistPanelLogic),
    I18nHOC(),
)(RedlistPanelUI);