import { h } from 'preact';

import { compose } from 'src/lib/tools';
import { Modal } from 'src/components/dialog';
import { LogicHOC } from 'src/components/logic-hoc';
import { I18nHOC } from 'src/components/i18n-hoc';
import { useSession, getSessionState } from 'src/hooks/session';
import { AccountBar } from './account-bar';
import { MenuBar } from './menu-bar';
import { Modulespace } from './modules';
import { Workspace } from './workspace';
import { AuthModal } from './auth-modal';

export const AppUI = ({ I18n, sessionState }) => {
    switch (sessionState) {
        case 'disconnected':
            return <Modal style={{ fontSize: '2rem' }}>{I18n.t('Connection en cours')}</Modal>;

        case 'connected':
            return <AuthModal />;

        case 'authenticated':
            return <div id="AppRoot"
                className="gap-medium"
                style={{
                    width: '100%',
                    height: '100%',
                    padding: '.25rem',
                    display: 'grid',
                    gridTemplateColumns: '20rem 1fr',
                    gridTemplateRows: 'auto auto 1fr',
                    gridTemplateAreas: '"account-bar account-bar" "menu-bar    menu-bar" "modules     workspace"',
                    placeItems: 'stretch stretch',
                }}>
                <AccountBar style={{ gridArea: 'account-bar' }} />
                <MenuBar style={{ gridArea: 'menu-bar' }} />
                <Modulespace style={{ gridArea: 'modules' }} />
                <Workspace style={{ gridArea: 'workspace' }} />
            </div>;
    }
};

export const AppLogic = ({ ...props }) => {
    const [session] = useSession();
    const sessionState = getSessionState(session);
    return { ...props, sessionState };
};

export const App = compose(
    LogicHOC(AppLogic),
    I18nHOC(),
)(AppUI);