import { useMemo } from 'preact/hooks';
import { useSession } from 'src/hooks/session';
import { Client } from 'src/lib/client';

export const useClient = () => {
    const [{ sessionId = null } = {}] = useSession();
    const client = useMemo(() => new Client(sessionId), [sessionId]);
    return client;
};

export default useClient;