import { coalesce } from 'src/lib/tools';
import { modifiableContextFactory } from 'src/lib/modifiable-context';

export const securityLevel = {
    sans: 0,
    simple: 10,
    demo: 20,
    utilisateur: 30,
    marque: 40,
    admin: 50,
};

export const {
    ModifiableContext: SessionContext,
    ModifiableProvider: SessionProvider,
    useModifiableContext: useSession,
} = modifiableContextFactory();

export const getSessionState = session => !session ? 'disconnected' : !(session.accountId) ? 'connected' : 'authenticated';

export const checkSecurityMin = (session, level) =>
    coalesce(securityLevel[level], securityLevel.sans) <=
        !session ?
        securityLevel.sans :
        coalesce(securityLevel[level], securityLevel.sans);

export default useSession;