import { h, Fragment } from 'preact';
import { useEffect, useState, useCallback } from 'preact/hooks';
import { Link } from 'preact-router/match';
import { faHistory, faCartPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import orderBy from 'lodash/orderBy';
import { compose, coalesce, guard } from 'src/lib/tools';
import useClient from 'src/hooks/client';
import useId from 'src/hooks/id-generator';
import useInput from 'src/hooks/input';
import { useSession, checkSecurityMin } from 'src/hooks/session';
import { I18nHOC } from 'src/components/i18n-hoc';
import { LogicHOC } from 'src/components/logic-hoc';
import { Icon } from 'src/components/icon';
import { Modal } from 'src/components/dialog';
import { EntryInput } from 'src/components/page-component';
import { NumberInput } from 'src/components/number-input';

export const ModifyCreditsModalUI = ({
    I18n,
    creditId,
    variationId,
    onDismiss,
    onSubmit,
    credits,
    credit,
    onCreditChange,
    variation,
    setVariation,
}) => (
    <Modal onDismiss={onDismiss} title={I18n.t('Modifier les crédits du compte')} class="property-grid">
        <form onSubmit={onSubmit} class="display-contents">
            <EntryInput htmlFor={creditId} title="Type de crédit">
                <select id={creditId} class="-fill" value={credit} onChange={onCreditChange}>
                    {credits.map(c =>
                        <option key={c.creditId} value={c.creditId}>{c.creditName}</option>)}
                </select>
            </EntryInput>

            <EntryInput htmlFor={variationId} title="variation">
                <NumberInput id={variationId} class="-fill" value={variation} onSet={setVariation} />
            </EntryInput>

            <button class="col-full button round" type="submit">
                <span>{I18n.t('Modifier')}</span>
            </button>
        </form>
    </Modal>
);

export const ModifyCreditsModalLogic = ({
    account,
    onDismiss,
    onValidate,
    ...props
}) => {
    const [creditId, variationId] = useId(2);
    const client = useClient();
    const { accountId } = coalesce(account, {});
    const [credits, setCredits] = useState([]);
    const [credit, _setCredit] = useState();
    const [variation, setVariation] = useState();
    const onCreditChange = useInput(_setCredit);

    const onSubmit = useCallback(e => {
        e.preventDefault();
        onValidate(credit, variation);
    }, [onValidate, credit, variation]);

    useEffect(() => (async () => {
        if (accountId) {
            const creds = await client.fax.account.enum_credits([accountId]);
            setCredits(creds);
        }
    })(), [accountId, client, setCredits]);

    return {
        ...props,
        creditId,
        variationId,
        onDismiss,
        onSubmit,
        credits,
        credit,
        onCreditChange,
        variation,
        setVariation,
    };
};

export const ModifyCreditsModal = compose(
    LogicHOC(ModifyCreditsModalLogic),
    I18nHOC(),
)(ModifyCreditsModalUI);

export const CreditsPanelUI = ({ I18n, account, accountId, creditBalances, canSetCredits, onShowModifyCreditsButtonClick, showModifyCreditsModal, onModifyCreditsModalDismiss, onModifyCreditsModalValidate }) => (
    guard(accountId) &&
    <div class="flex -column -X-stretch gap-medium padding-medium">
        <Link class="button round" activeClassName="-active" href={`/fax/shop/${accountId}`}>
            <Icon fixedWidth icon={faCartPlus} />
            <span>{I18n.t('Acheter des crédits')}</span>
        </Link>

        {guard(canSetCredits) && <Fragment>
            <button class="button round" onClick={onShowModifyCreditsButtonClick}>
                <Icon fixedWidth icon={faPen} />
                <span>{I18n.t('Modifier les crédits')}</span>
            </button>

            {guard(showModifyCreditsModal) &&
                <ModifyCreditsModal
                    account={account}
                    onDismiss={onModifyCreditsModalDismiss}
                    onValidate={onModifyCreditsModalValidate} />
            }
        </Fragment>}

        {guard(creditBalances && creditBalances.length) &&
            <table class="round border bg-white">
                <thead>
                    <tr>
                        <th>{I18n.t('Crédits')}</th>
                        <th>{I18n.t('Montant')}</th>
                    </tr>
                </thead>
                <tbody>
                    {creditBalances.map(balance =>
                        <tr>
                            <td class="padding-medium">{balance.creditName}</td>
                            <td class="padding-medium txt-align-right">{balance.balance}</td>
                        </tr>)}
                </tbody>
            </table>
        }

        <Link class="button round" activeClassName="-active" href={`/fax/history/${accountId}`}>
            <Icon fixedWidth icon={faHistory} />
            <span>{I18n.t('Historique fax')}</span>
        </Link>

    </div>
);

export const CreditsPanelLogic = ({ account, ...props }) => {
    const client = useClient();
    const [session] = useSession();
    const [creditBalances, setCreditBalances] = useState();
    const { accountId } = coalesce(account, {});
    const [showModifyCreditsModal, setShowModifyCreditsModal] = useState();
    const { securityLevel, accountId: connectedAccountId } = session;
    const canSetCredits = (checkSecurityMin(securityLevel, 'marque')) && connectedAccountId !== accountId;
    const onShowModifyCreditsButtonClick = useCallback(() => setShowModifyCreditsModal(true), [setShowModifyCreditsModal]);
    const onModifyCreditsModalDismiss = useCallback(() => setShowModifyCreditsModal(false), [setShowModifyCreditsModal]);
    const onModifyCreditsModalValidate = useCallback(async (credit, variation) => {
        setShowModifyCreditsModal(false);
        await client.fax.account.set_credit_balance(accountId, credit, variation);
        const balances = await client.fax.account.enum_credit_balances([accountId]);
        setCreditBalances(balances);
    }, [accountId, client, setCreditBalances, setShowModifyCreditsModal]);

    useEffect(() => (async () => {
        if (accountId) {
            const balances = await client.fax.account.enum_credit_balances([accountId]);
            setCreditBalances(balances);
        }
    })(), [accountId, client]);

    return {
        ...props,
        account,
        accountId,
        creditBalances: orderBy(creditBalances, [cb => cb.balance], ['desc']),
        canSetCredits,
        showModifyCreditsModal,
        onShowModifyCreditsButtonClick,
        onModifyCreditsModalDismiss,
        onModifyCreditsModalValidate,
    };
};

export const CreditsPanel = compose(
    LogicHOC(CreditsPanelLogic),
    I18nHOC(),
)(CreditsPanelUI);