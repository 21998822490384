import { h } from 'preact';
import { useRef, useState, useEffect } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import { useOnClickOutside } from 'src/hooks/click-outside';
import classNames from 'classnames';

import { guard } from 'src/lib/tools';
import { Icon } from './icon';

let popupRoot = null;
let lastCursorPos = { x: 0, y: 0 };

(() => {
    const mouseMouveHandler = e => lastCursorPos = { x: e.clientX, y: e.clientY };
    window.document.addEventListener('mousemove', mouseMouveHandler);
})();

export const PopupRoot = () => (
    <div id="PopupRoot" ref={r => popupRoot = r} />
);

export const Popup = ({
    targetRef,
    mainDirection = 'bottom', // "top", "bottom", "left", "right"
    crossDirection = 'left', // "top", "bottom", "left", "right"
    onDismiss,
    class: className,
    style,
    children,
    ...props
}) => {
    const PopupBackRef = useRef();

    const [rect, setRect] = useState();

    useOnClickOutside(PopupBackRef, onDismiss);

    useEffect(() => {
        if (targetRef) {
            const calcPos = () => {
                const { top, left, height, width } = targetRef.current ? targetRef.current.getBoundingClientRect() : {};
                // const popHeight = PopupBackRef.current.offsetHeight;
                // const popWidth = PopupBackRef.current.offsetWidth;
                const bottom = window.innerHeight - top - height;
                const right = window.innerWidth - left - width;

                const r = { visibility: 'visible' };

                switch (mainDirection) {
                    case 'bottom':
                        r.top = `${top + height}px`;
                        r.maxHeight = `${bottom - 20}px`;
                        r.minWidth = `${width}px`;
                        break;
                    case 'top':
                        r.bottom = `${bottom + height}px`;
                        r.maxHeight = `${top - 20}px`;
                        r.minWidth = `${width}px`;
                        break;
                    case 'left':
                        r.right = `${left + width}px`;
                        r.maxWidth = `${left - 20}px`;
                        r.minHeight = `${height}px`;
                        break;
                    case 'right':
                        r.left = `${right + width}px`;
                        r.maxWidth = `${right - 20}px`;
                        r.minHeight = `${height}px`;
                        break;
                }

                switch (crossDirection) {
                    case 'left':
                        r.left = `${left}px`;
                        r.maxWidth = `${right + width - 20}px`;
                        break;
                    case 'right':
                        r.right = `${right}px`;
                        r.maxWidth = `${left + width - 20}px`;
                        break;
                    case 'bottom':
                        r.bottom = `${bottom}px`;
                        r.maxHeight = `${top + width - 20}px`;
                        break;
                    case 'top':
                        r.top = `${top}px`;
                        r.maxHeight = `${bottom + width - 20}px`;
                        break;
                }

                setRect(r);
            };
            const intervalHandler = setInterval(calcPos, 100);
            return () => clearInterval(intervalHandler);
        }
        {
            const popHeight = PopupBackRef.current.offsetHeight;
            const popWidth = PopupBackRef.current.offsetWidth;
            const top = lastCursorPos.y;
            const bottom = window.innerHeight - lastCursorPos.y;
            const left = lastCursorPos.x;
            const right = window.innerWidth - lastCursorPos.x;
            const r = { visibility: 'visible' };

            if (popHeight > bottom && bottom < top) {
                r.bottom = `${bottom}px`;
                r.maxHeight = `${top - 20}px`;
            } else {
                r.top = `${top}px`;
                r.maxHeight = `${bottom - 20}px`;
            }

            if (popWidth > right && right < left) {
                r.right = `${right}px`;
                r.maxWidth = `${left - 20}px`;
            } else {
                r.left = `${left}px`;
                r.maxWidth = `${right - 20}px`;
            }
            setRect(r);
        }
    }, [crossDirection, mainDirection, setRect, targetRef]);

    return createPortal(
        <menu ref={PopupBackRef} class={classNames(className, 'round flex -column padding-large bg-white')} style={{ ...style, ...rect }} {...props}>
            {children}
        </menu>,
        popupRoot,
    );
};

export const ContextMenuUI = ({
    menuEntries,
    ...props
}) => (
    guard(menuEntries && menuEntries.length) &&
    <Popup {...props}><ul style={{ overflow: 'auto' }}>
        {menuEntries.map(({ icon, text, cb }) =>
            <li><a class="clickable flex -row  -X-center roll-over padding-medium" onClick={cb}>
                <Icon fixedWidth class="padding-small" icon={icon} />
                <span class="-fill padding-small">{text()}</span>
            </a></li>)}
    </ul></Popup>
);