import { h } from 'preact';
import { useRef, useState, useCallback } from 'preact/hooks';
import { faFax, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { compose, guard, coalesce } from 'src/lib/tools';
import { supportedCultures } from 'src/hooks/i18n';
import useAccount from 'src/hooks/account';
import { Popup } from 'src/components/popup';
import { Icon } from 'src/components/icon';
import { I18nHOC } from 'src/components/i18n-hoc';
import { LogicHOC } from 'src/components/logic-hoc';

export const LanguageButtonUI = ({ display, flag, OnClick }) => (
    <li onClick={OnClick} class="padding-medium roll-over">{flag} {display}</li>
);

export const LanguageButtonLogic = ({ culture, onCultureSelected, ...props }) => {
    const OnClick = useCallback(() => onCultureSelected(culture), [culture, onCultureSelected]);
    return { ...props, culture, OnClick };
};

export const LanguageButton = compose(
    LogicHOC(LanguageButtonLogic),
)(LanguageButtonUI);

export const MenuBarUI = ({ I18n, style, languageButtonRef, isShowingLanguagePopup, showLanguagePopup, onDismissLanguagePopup, currentCulture, onCultureSelected }) => (
    <menu style={style} class="gap-medium flex -row -X-stretch">
        <a class="button round" href={'/fax/campaigns'}>
            <Icon fixedWidth icon={faFax} />
            <span>{I18n.t('Vue globale fax')}</span>
        </a>

        <button onClick={showLanguagePopup} ref={languageButtonRef} class="button round" style={{ marginLeft: 'auto' }}>
            <span>{supportedCultures[currentCulture].flag} {supportedCultures[currentCulture].display}</span>
            <Icon fixedWidth icon={faCaretDown} />
        </button>

        {guard(isShowingLanguagePopup) &&
            <Popup crossDirection="right" targetRef={languageButtonRef} onDismiss={onDismissLanguagePopup}><ul>
                {Object.entries(supportedCultures).map(([culture, { display, flag }]) =>
                    <LanguageButton key={culture} culture={culture} display={display} flag={flag} onCultureSelected={onCultureSelected} />)}
            </ul></Popup>
        }
    </menu>
);

export const MenuBarLogic = ({ I18n, currentCulture, setCurrentCulture, ...props }) => {
    const [account] = useAccount();
    const { accountId } = coalesce(account, {});
    const languageButtonRef = useRef();
    const [isShowingLanguagePopup, setIsShowingLanguagePopup] = useState(false);
    const onCultureSelected = useCallback(culture => {
        setCurrentCulture(culture);
        setIsShowingLanguagePopup(false);
    }, [setCurrentCulture, setIsShowingLanguagePopup]);
    const showLanguagePopup = useCallback(() => setIsShowingLanguagePopup(true), [setIsShowingLanguagePopup]);
    const onDismissLanguagePopup = useCallback(() => setIsShowingLanguagePopup(false), [setIsShowingLanguagePopup]);

    return { ...props, accountId, languageButtonRef, isShowingLanguagePopup, showLanguagePopup, onDismissLanguagePopup, currentCulture, onCultureSelected };
};

export const MenuBar = compose(
    I18nHOC(),
    LogicHOC(MenuBarLogic),
    I18nHOC(),
)(MenuBarUI);